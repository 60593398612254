import { Radio, Space, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save } from '../../../features/save/saveSlice';
import { useNavigate } from 'react-router-dom';

export default function ActionStep({ prev }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	const { newProjectObj } = useSelector((state) => state.obj);
	const { saving } = useSelector((state) => state.save);

	const [value, setValue] = useState('');
	const [reason, setreason] = useState('');

	const onChange = (e) => {
		setValue(e.target.value);
	};

	async function handleApprove() {
		const saveObj = {
			...newProjectObj,
			projStatus: 'DRAFT',
			projMemo: reason,
			projApprovedBy: user.usrId,
			projApprovedByAcc: user.usrAccId,
			url: '/usr/save_project.action',
		};
		await dispatch(save(saveObj));
		await navigate('/pipelines/new-projects');
	}

	async function handleReject() {
		const saveObj = {
			...newProjectObj,
			projStatus: 'REJECT',
			projMemo: reason,
			projApprovedBy: user.usrId,
			projApprovedByAcc: user.usrAccId,
			url: '/usr/save_project.action',
		};
		await dispatch(save(saveObj));
		await navigate('/pipelines/new-projects');
	}

	useEffect(() => {}, [newProjectObj]);

	return (
		<>
			{newProjectObj?.projStatus === 'DRAFT' ||
			newProjectObj?.projStatus === 'REJECT' ? (
				<>
					<div className='flex flex-col gap-y-[1.06rem]'>
						<div className='info_desc_card_2'>
							<div className='w-full border-b border-[#F2F4F7] pb-[.75rem]'>
								The Project was{' '}
								{newProjectObj?.projStatus === 'REJECT'
									? 'rejected'
									: 'approved'}{' '}
								by {newProjectObj?.approverName ?? 'N/A'} From{' '}
								{newProjectObj?.bankName ?? 'N/A'}
							</div>
						</div>

						<div className='info_desc_card_2'>
							<div className='w-full border-b border-[#F2F4F7] pb-[.75rem]'>
								<span className='paragraph_1'>Reason</span>
								<span className='paragraph_2'>{newProjectObj?.projMemo}</span>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='flex flex-col gap-y-[1.06rem]'>
						<div className='info_desc_card_2'>
							<div className='w-full border-b border-[#F2F4F7] pb-[.75rem]'>
								<span className='action_header_txt'>
									How would you like to proceed with this application?
								</span>
							</div>

							<Radio.Group onChange={onChange} value={value}>
								<Space direction='vertical'>
									<Radio value={'DRAFT'}>Approve</Radio>
									<Radio className='reject' value={'REJECT'}>
										Reject
									</Radio>
								</Space>
							</Radio.Group>
						</div>

						<div className=''>
							<TextArea
								onChange={(e) => setreason(e.target.value)}
								placeholder='Reason'
								cols={4}
								rows={6}
							/>
						</div>
					</div>

					<div className='mt-[8.75rem] flex flex-col'>
						{value === 'DRAFT' ? (
							<button
								disabled={saving}
								onClick={() => handleApprove()}
								className='cstm-btn'>
								{saving ? <Spin /> : 'Approve'}
							</button>
						) : value === 'REJECT' ? (
							<button
								disabled={saving}
								onClick={() => handleReject()}
								className='cstm-btn !bg-[#CE1821]'>
								{saving ? <Spin /> : 'Reject'}
							</button>
						) : null}
					</div>
				</>
			)}
		</>
	);
}
