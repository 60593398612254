import React, { useEffect, useState } from 'react';
import DataCard from './components/DataCard';
import CountyPerformance from './components/CountyPerformance';
import OptimizationGoal from './components/OptimizationGoal';
import SectionTwo from './components/SectionTwo';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumberWithCommas } from '../../../utils';
import { fetchClientInfo, fetchCountyCount, fetchCountyGraph, fetchGraphForAll, fetchOverAllDetails, fetchProjectDetails } from '../../../features/dashboard/dashSlice';
import PageHeader from '../../../components/PageHeader';
import BreadCrumb from '../../../layout/BreadCrumb';

const breadList = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Impact Dashboard',
		href: '/',
	},
	{
		title: 'Dashboard',
	},
];

const ImpactDashboard = () => {
	const dispatch = useDispatch();
	const { data, countyCount, graphData, clientInfo } = useSelector(
		(state) => state.dash
	);
	const [selected, setSelected] = useState('Total');

	const getData = async () => {
		if (selected === 'Total') {
			dispatch(fetchGraphForAll());
			dispatch(fetchCountyCount());
			dispatch(fetchOverAllDetails());
			dispatch(fetchProjectDetails(selected === 'Total' ? '' : selected));
		} else {
			dispatch(fetchCountyGraph(selected));
			dispatch(fetchClientInfo(selected));
		}
	};

	useEffect(() => {
		getData();
	}, [selected]);

	const total = countyCount?.reduce(
		(accumulator, currentValue) => accumulator + currentValue.totalCount,
		0
	);

	const DataCardElement = countyCount
		.slice()
		.sort((a, b) => b.totalCount - a.totalCount)
		.slice(0, 3)
		.map((card) => {
			return (
				<>
					<button onClick={() => setSelected(card.jorgClientCounty)}>
						<DataCard
							selected={selected}
							key={card.jorgClientCounty}
							count={formatNumberWithCommas(card.totalCount)}
							percent={((card.totalCount / total) * 100).toFixed(0)}
							title={card.jorgClientCounty}
						/>
					</button>
				</>
			);
		});

	return (
		<div className='flex flex-col h-screen  w-[100%]'>
			<PageHeader header={'Impact Dashboard'} />
			<div className='mt-[.94rem]'>
				<BreadCrumb breadList={breadList} />
			</div>

			<div className='flex flex-row h-[20%] mt-[3rem]'>
				<button onClick={() => setSelected('Total')}>
					<DataCard
						selected={selected}
						count={formatNumberWithCommas(total)}
						percent={12}
						title={'Total'}
					/>
				</button>
				{DataCardElement}{' '}
			</div>
			<div className='flex m-5 justify-center'></div>
			<SectionTwo
				clientInfo={clientInfo}
				selected={selected}
				graphData={graphData}
				countyCount={countyCount}
			/>
			<CountyPerformance
				selected={selected}
				clientInfo={clientInfo}
				countyCount={countyCount}
			/>
			<div className='w-[50%]'>
				<OptimizationGoal />
			</div>
		</div>
	);
};

export default ImpactDashboard;
