import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './layout/SideBar';
import Header from './layout/Header';
import { useEffect } from 'react';
import { fetchCurrentUser, logout } from './features/auth/authSlice';

export const ProtectedRoute = () => {
	const dispatch = useDispatch();

	const { isActive, authLoading, user } = useSelector((state) => state.auth);

	const hash = window.location.hash;

	const params = hash.substring(1).split('/');

	const userId = params[1];
	const userAccountId = params[2];

	async function handleLogout() {
		if (isActive) {
			return;
		}
		await dispatch(logout());
		window.location.href = await 'https://fundmywater.com/login.action#/login';
	}

	async function fetchLoggedInUser() {
		await dispatch(
			fetchCurrentUser({
				accId: userAccountId,
				usrId: userId,
			})
		);
	}

	useEffect(() => {
		if (!userAccountId && !userId) {
			handleLogout();
		}
	}, [isActive, authLoading, user]);

	useEffect(() => {
		if (userAccountId && userId) {
			fetchLoggedInUser();
		}
	}, []);

	if (authLoading) {
		return <div class='loading'>Loading&#8230;</div>;
	}

	return (
		<>
			<div className='flex  items-start justify-start w-full'>
				<SideBar />
				<div className='flex flex-col items-start justify-start w-full'>
					<div className='flex flex-col w-full'>
						<Header />
					</div>
					<div className='pl-[2.88rem] pt-[3.33rem] pr-[6.81rem] w-full'>
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
};
