import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	authLoading: false,
	user: {},
	// user: {
	// 	usrId: 868,
	// 	usrAccId: 2917,
	// },
	isActive: false,
};

export const fetchCurrentUser = createAsyncThunk(
	'authSlice/fetchCurrentUser',
	async ({ accId, usrId }) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchUsers.action?accId=${accId ?? ''}&usrId=${usrId ?? ''}`)
			.then((res) => {
					if (!res.data.success) {
						return Promise.reject(res.data.message);
					}
				return res.data.data.result;
			});
		return res;
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = {};
			state.isActive = false;
			localStorage.clear();
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCurrentUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchCurrentUser.fulfilled, (state, action) => {
				state.authLoading = false;
				state.isActive = true;
				state.user = action.payload[0];
			})
			.addCase(fetchCurrentUser.rejected, (state, action) => {
				state.authLoading = false;
			});
	},
});

export default authSlice.reducer;
export const { logout } = authSlice.actions;
