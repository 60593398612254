import { Outlet } from 'react-router-dom';

export default function Root() {
	return (
		<div className='flex flex-col w-full h-full'>
			<div className='flex flex-col items-start w-full h-full'>
				<Outlet />
			</div>
		</div>
	);
}
