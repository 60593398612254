import { useNavigate } from 'react-router-dom';
import ProjectView from './ProjectView';
import { useSelector } from 'react-redux';

export default function ExpressionView() {
	const navigate = useNavigate();

	const { projectObj } = useSelector((state) => state.obj);

	return (
		<>
			<ProjectView title={'Project Summary'} pageUrl={'/'} />

			<div className='w-full flex justify-end mt-[4.25rem] mb-[5rem]'>
				<button
					onClick={() =>
						projectObj?.projStatus === 'APPROVED'
							? navigate(`/${projectObj?.accTradeName}/screening`)
							: navigate('/screening')
					}
					className='cstm-btn-2'>
					Start Screening
				</button>
			</div>
		</>
	);
}
