import React, { useEffect, useState } from 'react';
import ProjectDetail from './components/ProjectDetail';
import ProjectList from './components/ProjectList';
import PageHeader from '../../../components/PageHeader';
import BreadCrumb from '../../../layout/BreadCrumb';
import Search from '../../../components/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProjectDevelopments } from '../../../features/fetch/fetchSlice';
import SingleProject from './components/SingleProject';

const breadList = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Projects',
		href: '/',
	},
	{
		title: 'Project Development',
	},
];

const ProjectDevelopment = () => {
	const dispatch = useDispatch();

	const { projectDevelopment } = useSelector((state) => state.fetch);
	const { user } = useSelector((state) => state.auth);

	const [value, setvalue] = useState('');
	const [activeItem, setactiveItem] = useState({});

	const arrayCopy = [...projectDevelopment]?.filter(
		(item) =>
			String(item?.wsp).toUpperCase().includes(String(value).toUpperCase()) ||
			String(item?.accCounty)
				.toUpperCase()
				.includes(String(value).toUpperCase())
	);

	function handleVActiveProjectChange(obj) {
		setactiveItem(obj);
	}

	function handleValueChange(val) {
		setvalue(val);
	}

	async function handleFetch() {
		await dispatch(fetchProjectDevelopments(user?.usrAccId));
	}

	useEffect(() => {}, [projectDevelopment, value, activeItem]);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<div className='flex flex-col min-h-screen  w-full'>
			<PageHeader header={'Project Development'} />
			<div className='mt-[.94rem]'>
				<BreadCrumb breadList={breadList} />
			</div>
			<div className='mt-[2.19rem] w-[40%]'>
				<Search handleValueChange={handleValueChange} />
			</div>

			<div className='flex flex-row bg-white mt-[3rem] w-full h-auto '>
				<div className='flex w-[40%] p-[1.63rem] min-h-[100%] flex-col'>
					{arrayCopy?.map((item) => {
						return (
							<>
								<SingleProject
									activeId={activeItem?.pjdId}
									handleVActiveProjectChange={handleVActiveProjectChange}
									item={item}
									bgColor={'#E6EAEF'}
								/>
							</>
						);
					})}
				</div>
				<div className='flex w-[60%] min-h-full flex-col pr-[1.63rem] py-[1.63rem]'>
					{Object.keys(activeItem)?.length ? (
						<ProjectDetail handleFetch={handleFetch} activeItem={activeItem} />
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ProjectDevelopment;
