import { Collapse, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import FinalVerdictDetails from './final_verdict_panel/FinalVerdictDetails';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScoreStep } from '../../../features/fetch/fetchSlice';
import { save } from '../../../features/save/saveSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
			fill='#0170BD'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M12.355 9L7 3.43725L1.645 9L0 7.28745L7 0L14 7.28745L12.355 9Z'
			fill='#0170BD'
		/>
	</svg>
);

const mainIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 9.34766)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 9.34766)'
			fill='#0170BD'
		/>
	</svg>
);

export default function FinalVerdictEditableStep({ prev }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modal, contextHolder] = Modal.useModal();

	const { projectObj } = useSelector((state) => state.obj);
	const { projectScores } = useSelector((state) => state.fetch);
	const { saving } = useSelector((state) => state.save);

	let final = projectScores?.find((item) => item?.sctStep === 'FINAL_VERDICT');

	const [activeKey, setactiveKey] = useState(['0']);
	const [comment, setComment] = useState(final?.sctComment ?? '');

	const totalScore = projectScores?.reduce((accumulator, currentValue) => {
		if (currentValue.sctStep === 'FINAL_VERDICT') {
			return accumulator;
		}
		return accumulator + currentValue.sctTotalScore;
	}, 0);

	const confirm = () => {
		modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			onOk: handleSubmit,
			content: 'Are you sure you want to offer letter of intent?',
			okText: 'Submit',
			cancelText: 'Cancel',
		});
	};

	const onChange = (key) => {
		setactiveKey(key);
	};

	async function handleFetch() {
		await dispatch(fetchScoreStep(projectObj?.projId));
	}

	async function handleSubmit() {
		let saveObj = {
			projId: projectObj?.projId,
			projStatus: 'COMMITTED',
			url: '/usr/save_project.action',
		};

		await dispatch(save(saveObj));
		await navigate('/pipelines/deal-sourcing');
	}

	useEffect(() => {}, [projectObj, projectScores]);

	useEffect(() => {
		handleFetch();
	}, []);

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Final verdict</span>
				</div>
			),
			children: <FinalVerdictDetails />,
		},
	];

	return (
		<>
			{contextHolder}

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mt-[3rem]'>
				<button className='cstm-btn-2 cursor-text !bg-[#44BA41]'>
					Total score: {totalScore} Points
				</button>
			</div>

			<div className='mt-[3rem] flex flex-col'>
				<span className='label_1'>Final verdict</span>

				<TextArea
					readOnly
					value={comment ?? 'No comments provided'}
					rows={5}
					className='textarea_blue mt-[1rem]'
				/>
			</div>

			<div className='w-full flex flex-col items-center justify-center gap-y-[2.67rem] mt-[5.23rem] mb-[5rem]'>
				{projectObj?.projStatus === 'APPROVED' ? (
					<>
						<button disabled={saving} onClick={confirm} className='cstm-btn-2'>
							{saving ? <Spin /> : ' Offer letter of intent'}
						</button>
						<div className='w-full flex items-center justify-center gap-x-[3.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>6/6</span>
						</div>
					</>
				) : (
					<>
						<div className='w-full flex items-center justify-center gap-x-[11.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>6/6</span>
						</div>
					</>
				)}
			</div>
		</>
	);
}
