import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	loading: false,
	newProjects: [],
	projectDirectors: [],
	incompleteProjects: [],
	managementTools: [],
	operationStaffs: [],
	waterSources: [],
	pipeExtents: [],
	storageCapacities: [],
	banksInfo: [],
	budgetItems: [],
	projectScores: [],
	completedProjects: [],
	dealSourcing: [],
	commitedProjects: [],
	viableProjects: [],
	bids: [],
	loanPerfections: [],
	wsps: [],
	projectDevelopment: [],
	projectScoreStepOne: [],
	projectScoreStepTwo: [],
	projectScoreStepThree: [],
	projectScoreStepFour: [],
	projectScoreStepFive: [],
	projectScoreStepSix: [],
	prospects: [],
};

export const fetchNewProjects = createAsyncThunk(
	'fetchSlice/fetchNewProjects',
	async () => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=null`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectDirectors = createAsyncThunk(
	'fetchSlice/fetchProjectDirectors',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchDirectors.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchIncompleteProjects = createAsyncThunk(
	'fetchSlice/fetchIncompleteProjects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=DRAFT`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchManagementTools = createAsyncThunk(
	'fetchSlice/fetchManagementTools',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchManagementTool.action?mtProjId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchOperationStaff = createAsyncThunk(
	'fetchSlice/fetchOperationStaff',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchOperationsStaff.action?osProjId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchWaterSource = createAsyncThunk(
	'fetchSlice/fetchWaterSource',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchWaterSource.action?wsProjId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchPipeExtents = createAsyncThunk(
	'fetchSlice/fetchPipeExtents',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchPipeExtents.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchTotalCapacityOfStorages = createAsyncThunk(
	'fetchSlice/fetchTotalCapacityOfStorages',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchTotalCapacityOfStorages.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchBankInfos = createAsyncThunk(
	'fetchSlice/fetchBankInfos',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchBankInfos.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchBudgetItems = createAsyncThunk(
	'fetchSlice/fetchBudgetItems',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchBudgetItems.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchScoreStep = createAsyncThunk(
	'fetchSlice/fetchScoreStep',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchScoreStep.action?projId=${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchCompleteProjects = createAsyncThunk(
	'fetchSlice/fetchCompleteProjects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=PENDING_APPROVAL`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchDealSourcingProjects = createAsyncThunk(
	'fetchSlice/fetchDealSourcingProjects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=APPROVED`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchCommitedProjects = createAsyncThunk(
	'fetchSlice/fetchCommitedProjects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=COMMITTED`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchViableProjects = createAsyncThunk(
	'fetchSlice/fetchViableProjects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=DEAL_SOURCING`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchBids = createAsyncThunk(
	'fetchSlice/fetchBids',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchLoanTermConditions.action?ltcAccId=${id ?? ''}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchLoanPerfection = createAsyncThunk(
	'fetchSlice/fetchLoanPerfection',
	async (id) => {
		const res = await axiosInstance
			.get(
				`${url}/usr/fetchLoanTermConditions.action?ltcAccId=${
					id ?? ''
				}&ltcStatus=ACCEPTED`
			)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchWsp = createAsyncThunk('fetchSlice/fetchWsp', async (id) => {
	const res = await axiosInstance
		.get(`${url}/usr/fetchWsp.action`)
		.then((res) => res.data.data.result);
	return res;
});

export const fetchProjectDevelopments = createAsyncThunk(
	'fetchSlice/fetchProjectDevelopments',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/dash/fetchProjectDevelopment.action?pjdAccId=${id ?? ''}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectScoreStepOne = createAsyncThunk(
	'fetchSlice/fetchProjectScoreStepOne',
	async (id) => {
		const res = await axiosInstance
			.get(
				`${url}/usr/fetchProjectScore.action?projId=${id}&page=GOVERNANCE_INFO`
			)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectScoreSteptwo = createAsyncThunk(
	'fetchSlice/fetchProjectScoreSteptwo',
	async (id) => {
		const res = await axiosInstance
			.get(
				`${url}/usr/fetchProjectScore.action?projId=${id}&page=PROJECT_DESCRIPTION`
			)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectScoreStepthree = createAsyncThunk(
	'fetchSlice/fetchProjectScoreStepthree',
	async (id) => {
		const res = await axiosInstance
			.get(
				`${url}/usr/fetchProjectScore.action?projId=${id}&page=CUSTOMER_DETAIL`
			)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectScoreStepFour = createAsyncThunk(
	'fetchSlice/fetchProjectScoreStepFour',
	async (id) => {
		const res = await axiosInstance
			.get(
				`${url}/usr/fetchProjectScore.action?projId=${id}&page=FINANCIAL_SUMMARY`
			)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchProjectScoreStepFive = createAsyncThunk(
	'fetchSlice/fetchProjectScoreStepFive',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchProjectScore.action?projId=${id}&page=LOAN_INFO`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchWashProspects = createAsyncThunk(
	'fetchSlice/fetchWashProspects',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?projStatus=WASH`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchSlice = createSlice({
	name: 'fetch',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchWashProspects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchWashProspects.fulfilled, (state, action) => {
				state.loading = false;
				state.prospects = action.payload;
			})
			.addCase(fetchWashProspects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectScoreStepFive.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectScoreStepFive.fulfilled, (state, action) => {
				state.loading = false;
				state.projectScoreStepFive = action.payload;
			})
			.addCase(fetchProjectScoreStepFive.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectScoreStepFour.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectScoreStepFour.fulfilled, (state, action) => {
				state.loading = false;
				state.projectScoreStepFour = action.payload;
			})
			.addCase(fetchProjectScoreStepFour.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectScoreStepthree.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectScoreStepthree.fulfilled, (state, action) => {
				state.loading = false;
				state.projectScoreStepThree = action.payload;
			})
			.addCase(fetchProjectScoreStepthree.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectScoreSteptwo.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectScoreSteptwo.fulfilled, (state, action) => {
				state.loading = false;
				state.projectScoreStepTwo = action.payload;
			})
			.addCase(fetchProjectScoreSteptwo.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectScoreStepOne.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectScoreStepOne.fulfilled, (state, action) => {
				state.loading = false;
				state.projectScoreStepOne = action.payload;
			})
			.addCase(fetchProjectScoreStepOne.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectDevelopments.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectDevelopments.fulfilled, (state, action) => {
				state.loading = false;
				state.projectDevelopment = action.payload;
			})
			.addCase(fetchProjectDevelopments.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchWsp.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchWsp.fulfilled, (state, action) => {
				state.loading = false;
				state.wsps = action.payload;
			})
			.addCase(fetchWsp.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchLoanPerfection.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchLoanPerfection.fulfilled, (state, action) => {
				state.loading = false;
				state.loanPerfections = action.payload;
			})
			.addCase(fetchLoanPerfection.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchBids.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchBids.fulfilled, (state, action) => {
				state.loading = false;
				state.bids = action.payload;
			})
			.addCase(fetchBids.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchViableProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchViableProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.viableProjects = action.payload;
			})
			.addCase(fetchViableProjects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchCommitedProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCommitedProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.commitedProjects = action.payload;
			})
			.addCase(fetchCommitedProjects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchDealSourcingProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchDealSourcingProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.dealSourcing = action.payload;
			})
			.addCase(fetchDealSourcingProjects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchCompleteProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCompleteProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.completedProjects = action.payload;
			})
			.addCase(fetchCompleteProjects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchScoreStep.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchScoreStep.fulfilled, (state, action) => {
				state.loading = false;
				state.projectScores = action.payload;
			})
			.addCase(fetchScoreStep.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchBudgetItems.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchBudgetItems.fulfilled, (state, action) => {
				state.loading = false;
				state.budgetItems = action.payload;
			})
			.addCase(fetchBudgetItems.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchBankInfos.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchBankInfos.fulfilled, (state, action) => {
				state.loading = false;
				state.banksInfo = action.payload;
			})
			.addCase(fetchBankInfos.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchTotalCapacityOfStorages.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchTotalCapacityOfStorages.fulfilled, (state, action) => {
				state.loading = false;
				state.storageCapacities = action.payload;
			})
			.addCase(fetchTotalCapacityOfStorages.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchPipeExtents.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchPipeExtents.fulfilled, (state, action) => {
				state.loading = false;
				state.pipeExtents = action.payload;
			})
			.addCase(fetchPipeExtents.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchWaterSource.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchWaterSource.fulfilled, (state, action) => {
				state.loading = false;
				state.waterSources = action.payload;
			})
			.addCase(fetchWaterSource.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchOperationStaff.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchOperationStaff.fulfilled, (state, action) => {
				state.loading = false;
				state.operationStaffs = action.payload;
			})
			.addCase(fetchOperationStaff.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchManagementTools.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchManagementTools.fulfilled, (state, action) => {
				state.loading = false;
				state.managementTools = action.payload;
			})
			.addCase(fetchManagementTools.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchIncompleteProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchIncompleteProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.incompleteProjects = action.payload;
			})
			.addCase(fetchIncompleteProjects.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProjectDirectors.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProjectDirectors.fulfilled, (state, action) => {
				state.loading = false;
				state.projectDirectors = action.payload;
			})
			.addCase(fetchProjectDirectors.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchNewProjects.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchNewProjects.fulfilled, (state, action) => {
				state.loading = false;
				state.newProjects = action.payload;
			})
			.addCase(fetchNewProjects.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
