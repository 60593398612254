import { Col, Row, Table } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createData, formatPath, previewPaper } from '../../../../utils';
import { fetchProjectDirectors } from '../../../../features/fetch/fetchSlice';
import GradingComponent from '../../../../components/GradingComponent';

export default function ManagementDetails({ handleFetch }) {
	const dispatch = useDispatch();

	const { projectObj } = useSelector((state) => state.obj);
	const { projectScoreStepOne } = useSelector((state) => state.fetch);

	const { loading, projectDirectors } = useSelector((state) => state.fetch);

	async function handleFetchDirectors() {
		await dispatch(fetchProjectDirectors(projectObj?.projId));
	}

	useEffect(() => {}, [projectObj, projectDirectors, projectScoreStepOne]);


	useEffect(() => {
		handleFetchDirectors();
	}, []);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'dirName',
		},
		{
			title: 'Position',
			dataIndex: 'dirPosition',
		},
		{
			title: 'Telephone',
			dataIndex: 'dirPhone',
		},
		{
			title: 'Duration(Years)',
			dataIndex: 'dirDuration',
		},
		{
			title: 'Attachments',
			dataIndex: 'dirCopyOfId',
			render: (item) => (
				<button
					onClick={() => previewPaper(item)}
					className='flex items-center'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='19'
						height='13'
						viewBox='0 0 19 13'
						fill='none'>
						<path
							d='M9.5 1.73333C12.7732 1.73333 15.6923 3.57933 17.1173 6.5C15.6923 9.42067 12.7818 11.2667 9.5 11.2667C6.21818 11.2667 3.30773 9.42067 1.88273 6.5C3.30773 3.57933 6.22682 1.73333 9.5 1.73333ZM9.5 0C5.18182 0 1.49409 2.69533 0 6.5C1.49409 10.3047 5.18182 13 9.5 13C13.8182 13 17.5059 10.3047 19 6.5C17.5059 2.69533 13.8182 0 9.5 0ZM9.5 4.33333C10.6918 4.33333 11.6591 5.304 11.6591 6.5C11.6591 7.696 10.6918 8.66667 9.5 8.66667C8.30818 8.66667 7.34091 7.696 7.34091 6.5C7.34091 5.304 8.30818 4.33333 9.5 4.33333ZM9.5 2.6C7.35818 2.6 5.61364 4.35067 5.61364 6.5C5.61364 8.64933 7.35818 10.4 9.5 10.4C11.6418 10.4 13.3864 8.64933 13.3864 6.5C13.3864 4.35067 11.6418 2.6 9.5 2.6Z'
							fill='#0170BD'
						/>
					</svg>
					{item ? (
						<span className='ml-[.44rem] table_view_txt'>View File</span>
					) : (
						<span className='ml-[.44rem] panel_txt'>No File</span>
					)}
				</button>
			),
		},
	];

	const arr1 = [createData('', '', 'director', 5)];

	return (
		<>
			<div className='w-full mt-[2.75rem]'>
				<Table
					loading={loading}
					pagination={{
						defaultPageSize: 7,
						hideOnSinglePage: true,
						pageSizeOptions: [7, 15, 50, 100],
					}}
					columns={columns}
					dataSource={projectDirectors}
				/>

				<Col className='mt-[1rem]' span={24}>
					{arr1?.map((item) => {
						return (
							<GradingComponent
								handleFetch={handleFetch}
								arrayList={projectScoreStepOne}
								data={item.data}
								itemKey={item.itemKey}
								itemName={item.itemName}
								isFile={item.isFile}
								overalScore={item.overalScore}
							/>
						);
					})}
				</Col>
			</div>
		</>
	);
}
