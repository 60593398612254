import { Col, Collapse, Input, InputNumber, Modal, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import RiksMitigationPanel from './step_three_panels/RiksMitigationPanel';
import DelegatedServicePanel from './step_three_panels/DelegatedServicePanel';
import BillingTransitionPanel from './step_three_panels/BillingTransitionPanel';
import BusinessPlanPanel from './step_three_panels/BusinessPlanPanel';
import { save } from '../../features/save/saveSlice';
import { fetchSingleBid, uploadFile } from '../../features/obj/objSlice';
import toast from 'react-hot-toast';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M1.645 0L7 5.56275L12.355 0L14 1.71255L7 9L0 1.71255L1.645 0Z'
			fill='#0170BD'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='14'
		height='9'
		viewBox='0 0 14 9'
		fill='none'>
		<path
			d='M12.355 9L7 3.43725L1.645 9L0 7.28745L7 0L14 7.28745L12.355 9Z'
			fill='#0170BD'
		/>
	</svg>
);

const mainIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='8'
		height='13'
		viewBox='0 0 8 13'
		fill='none'>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 0.0625)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 4.70703)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 8 9.34766)'
			fill='#0170BD'
		/>
		<ellipse
			cx='1.65702'
			cy='1.66445'
			rx='1.65702'
			ry='1.66445'
			transform='matrix(-1 0 0 1 3.31445 9.34766)'
			fill='#0170BD'
		/>
	</svg>
);

export default function ProjectPreparationStepThreeView({ prev }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { projectObj, bidObj } = useSelector((state) => state.obj);
	const { saving } = useSelector((state) => state.save);
	const { user } = useSelector((state) => state.auth);

	const [activeKey, setactiveKey] = useState(['0']);
	const [formObj, setformObj] = useState(bidObj);

	function handleChange(field, value) {
		setformObj((prev) => ({
			...prev,
			[field]: value,
		}));
	}

	const handleFile = async (field, e) => {
		let file = e.target.files[0];
		const formData = new FormData();

		formData.append('file', file);

		const res = await dispatch(uploadFile(formData));

		setformObj((prev) => ({
			...prev,
			[field]: res?.payload,
		}));
	};

	const onChange = (key) => {
		setactiveKey(key);
	};

	const confirm = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			onOk: handleSubmit,
			content: 'Are you sure you want to offer term sheet to this project?',
			okText: 'Submit',
			cancelText: 'Cancel',
		});
	};

	async function handleFetch() {
		let obj = {
			id: user?.usrAccId,
			projId: projectObj?.projId,
		};
		await dispatch(fetchSingleBid(obj));
	}

	async function handleSubmit() {
		let saveObj = {
			...formObj,
			ltcId: formObj?.ltcId,
			ltcProjId: projectObj?.projId,
			ltcAccId: user?.usrAccId,
			url: '/usr/saveLoanTermCondition.action',
		};

		const res = await dispatch(save(saveObj));
		if (res?.payload?.success) {
			await toast.success('Term sheet successfully sent to WSP');
			await navigate('/deal-room/viable-projects');
		} else {
			toast.error(
				res?.payload?.messages?.message ??
					'An error occurred while making your request'
			);
		}
	}

	useEffect(() => {}, [projectObj, formObj]);

	useEffect(() => {
		setformObj(bidObj);
	}, [bidObj]);

	useEffect(() => {
		handleFetch();
	}, []);

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Business plan</span>
				</div>
			),
			children: <BusinessPlanPanel />,
		},
	];

	const items2 = [
		{
			key: '1',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>Risk Mitigation Plan</span>
				</div>
			),
			children: <RiksMitigationPanel />,
		},
	];

	const items3 = [
		{
			key: '2',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
						Delegated Service Provision Agreement
					</span>
				</div>
			),
			children: <DelegatedServicePanel />,
		},
	];

	const items4 = [
		{
			key: '3',
			label: (
				<div className='flex items-center'>
					{mainIcon}
					<span className='ml-[1rem] panel_header'>
						Billing Transition Plan
					</span>
				</div>
			),
			children: <BillingTransitionPanel />,
		},
	];

	return (
		<>
			<div
				style={{
					maxWidth: '100%',
					width: '40.68756rem',
					height: '0.0625rem',
					opacity: 0.3,
					background: 'var(--Blue-grey, #7E93AE)',
				}}
				className='mt-[1.13rem]'></div>

			<div className='project_view_company_txt mt-[2.19rem]'>
				{projectObj?.accTradeName} | SSWP No: N/A
			</div>

			<div className='project_view_contact_txt mt-[1.5rem]'>
				Contact Person: {projectObj?.usrFirstName ?? 'N/A'}{' '}
				{projectObj?.usrLastName ?? ''} - {projectObj?.usrMobileNumber}
			</div>

			<div className='mb-[1.88rem] mt-[2.44rem]'>
				<Collapse
					className='collapse-white'
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items2}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items3}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div className='mb-[1.88rem]'>
				<Collapse
					ghost
					expandIcon={({ isActive }) => (isActive ? activeIcon : expandIcon)}
					expandIconPosition={'end'}
					items={items4}
					activeKey={activeKey}
					onChange={onChange}
					accordion
				/>
			</div>

			<div
				style={{
					width: '100%',
					height: 'auto',
					border: '1px dotted #0873B9',
				}}
				className='bg-white p-[2rem] flex flex-col'>
				<span className='panel_header'>Term Sheet</span>
				<span className='fill_details_txt mt-[1.31rem]'>
					Please fill in the details below
				</span>

				<Row className='mt-[1rem]'>
					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Loan (KES)</span>
							<InputNumber
								readOnly={formObj?.ltcId}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
								formatter={(value) =>
									`${value}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}
								placeholder='Start typing here'
								onChange={(e) => handleChange('ltcLoanAmnt', e)}
								value={formObj?.ltcLoanAmnt}
								className='input_borderless'
							/>
						</div>
					</Col>

					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Interest Rate (%)</span>
							<InputNumber
								readOnly={formObj?.ltcId}
								onChange={(e) => handleChange('ltcLoanInterest', e)}
								value={formObj?.ltcLoanInterest}
								className='input_borderless'
							/>
						</div>
					</Col>

					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Loan Term (months)</span>
							<InputNumber
								readOnly={formObj?.ltcId}
								onChange={(e) => handleChange('ltcLoanTerm', e)}
								value={formObj?.ltcLoanTerm}
								className='input_borderless'
							/>
						</div>
					</Col>
					{/* 
					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Output Objective</span>
							<Input
								onChange={(e) => handleChange('ltcPurpose', e.target.value)}
								value={formObj?.bspLoanTerm}
								className='input_borderless'
							/>
						</div>
					</Col>

					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Baseline</span>
							<Input
								onChange={(e) => handleChange('bspLoanTerm', e.target.value)}
								value={formObj?.bspLoanTerm}
								className='input_borderless'
							/>
						</div>
					</Col>

					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Target</span>
							<Input
								onChange={(e) => handleChange('bspLoanTerm', e.target.value)}
								value={formObj?.bspLoanTerm}
								className='input_borderless'
							/>
						</div>
					</Col> */}

					<Col span={24}>
						<div className='panel_card w-full grid grid-cols-3 gap-x-[1rem]'>
							<span className='panel_txt_header'>Term Sheet attachment</span>
							<label
								className='cursor-pointer flex items-center'
								htmlFor='ltcTermsheetFile'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='18'
									height='12'
									viewBox='0 0 18 12'
									fill='none'>
									<path
										d='M14.5125 4.53C14.0025 1.9425 11.73 0 9 0C6.8325 0 4.95 1.23 4.0125 3.03C1.755 3.27 0 5.1825 0 7.5C0 9.9825 2.0175 12 4.5 12H14.25C16.32 12 18 10.32 18 8.25C18 6.27 16.4625 4.665 14.5125 4.53ZM14.25 10.5H4.5C2.8425 10.5 1.5 9.1575 1.5 7.5C1.5 5.9625 2.6475 4.68 4.17 4.5225L4.9725 4.44L5.3475 3.7275C6.06 2.355 7.455 1.5 9 1.5C10.965 1.5 12.66 2.895 13.0425 4.8225L13.2675 5.9475L14.415 6.03C15.585 6.105 16.5 7.0875 16.5 8.25C16.5 9.4875 15.4875 10.5 14.25 10.5ZM6 6.75H7.9125V9H10.0875V6.75H12L9 3.75L6 6.75Z'
										fill='#0170BD'
									/>
								</svg>
								<input
									disabled={formObj?.ltcId}
									id='ltcTermsheetFile'
									type='file'
									onChange={(e) => handleFile('ltcTermsheetFile', e)}
									hidden
								/>
								<span className='table_view_txt ml-[.44rem]'>
									{formObj?.ltcTermsheetFile
										? formObj?.ltcTermsheetFile?.split('_')?.pop()
										: 'Upload term sheet'}
								</span>
							</label>
						</div>
					</Col>
				</Row>
			</div>

			<div className='w-full flex flex-col items-center justify-center gap-y-[2.67rem] mt-[5.23rem] mb-[5rem]'>
				{projectObj?.projStatus === 'DEAL_SOURCING' && !formObj?.ltcId ? (
					<>
						<button disabled={saving} onClick={confirm} className='cstm-btn-2'>
							{saving ? <Spin /> : 'Offer Term Sheet'}
						</button>
						<div className='w-full flex items-center justify-center gap-x-[3.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>3/3</span>
						</div>
					</>
				) : (
					<>
						{/* <div className='flex w-full justify-center items-center'>
							<button disabled className='border-btn !text-[#44BA41]'>
								Term Sheet Already Sent
							</button>
						</div> */}
						<div className='w-full flex items-center justify-center gap-x-[11.67rem]'>
							<button disabled={saving} onClick={() => prev()}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='62'
									height='24'
									viewBox='0 0 62 24'
									fill='none'>
									<path
										d='M15.4613 15L62 15V9L15.4613 9L15.4613 0L0 12L15.4613 24V15Z'
										fill='#0873B9'
									/>
								</svg>
							</button>

							<span className='panel_num_txt'>3/3</span>
						</div>
					</>
				)}
			</div>
		</>
	);
}
